import React from "react";
import {graphql} from "gatsby";
import Img from "gatsby-image";

import Layout from "../components/layout";
import SEO from "../components/seo";
import * as propType from "prop-types";
import HighlightCard from "../components/highlightcard"
import { Link } from "../../.cache/gatsby-browser-entry"

const Badge = (name, icon, link) => {
    return (
        <a href={link} target="_blank">
            {/*{name}*/}
            <span className="fa-stack border border-muted fa-lg bg-light" title={name}>
                {/*<i className="far fa-square fa-stack-2x"/>*/}
                <i className={icon + " fa-stack-1x fa-fw text-dark"}/>
            </span>
        </a>
    );
};

const BadgeList = (badges) => {
    if (badges !== undefined && badges.length > 0) {
        return (
            <ul className="list-inline ml-0 mb-0">
                {badges.map((x, index) => {
                    return (
                        <li key={index} className="list-inline-item">{x}</li>);
                })}
            </ul>
        );
    }
};

const Box = ({children}) => {
    return (
        <div className="container-fluid p-3 mb-3">
            {children}
        </div>
    );
};

Box.propTypes = {
    children: propType.node,
};

const ShortProfile = ({name, position, keywords, portrait}) => {
    const badges = [
        // new Badge("Group Page", "fas fa-flask", "https://niessnerlab.org/members/manuel_dahnert/profile.html"),
        Badge("CV", "ai ai-cv", "/dahnert-cv.pdf"),
        Badge("Group Page", "fas fa-users",
            "https://niessnerlab.org/members/manuel_dahnert/profile.html"),
        Badge("Google Scholar", "ai ai-google-scholar",
            "https://scholar.google.com/citations?user=R1O631AAAAAJ"),
        Badge("eMail", "fas fa-envelope", "mailto:manuel.dahnert@tum.de"),
        Badge("GitHub", "fab fa-github", "https://github.com/xheon"),
        Badge("GitLab", "fab fa-gitlab", "https://gitlab.com/xheon"),
        Badge("LinkedIn", "fab fa-linkedin",
            "htts://www.linkedin.com/in/manuel-dahnert"),
    ];

    const badgelist = BadgeList(badges);



    return (
        <div className="card border-0 mb-3">
            <div className="row no-gutters">
                <div className="col-md-2 text-center">
                    <Img fluid={portrait.childImageSharp.fluid} alt=""
                        className="card-img mb-0 rounded-circle mx-auto" style={{maxWidth: 180}}/>
                </div>
                <div className="col-md-10">
                    <div className="card-body ml-2 pl-3 pt-3 pb-0">
                        <div className="pb-0">
                            <h4 className="card-title mb-2 text-center text-md-start">{name}</h4>
                        </div>
                        <div className="pb-3 pt-0 card-text text-center text-md-start">{position}</div>
                        <div className="pb-3 pt-0 card-text font-italic text-center text-md-start">
                          <ul className="list-inline">
                            {keywords.map(keyword => (
                              <li className="list-inline-item mr-4" key={keyword}>{keyword}</li>
                            ))}

                          </ul>
                        </div>
                        <div className="pt-2 card-text text-center text-md-start">{badgelist}</div>
                    </div>
                </div>
            </div>
        </div>
    );
};

ShortProfile.propTypes = {
    name: propType.string,
    position: propType.string,
    keywords: propType.array,
    badges: propType.array,
};



const Highlights = ({ highlights }) => {

    return (
        <Box>
            <div className="row mb-3">
                <div className="col">
                    <h5 className="text-secondary text-center">Highlights</h5>
                </div>
            </div>
            <div className="row row-cols-1 row-cols-lg-3 my-2">
                {highlights.map(highlight => (
                    <div className="col" key={highlight.slug}>
                        <HighlightCard
                            title={highlight.frontmatter.title}
                            slug={highlight.frontmatter.slug}
                            conference={highlight.frontmatter.conference_short}
                            year={highlight.frontmatter.year}
                            thumb={highlight.frontmatter.thumb}

                        />
                    </div>
                ))}
                {/*<div className="col">*/}
                {/*    <HighlightCard*/}
                {/*        title={"Joint Embedding"}*/}
                {/*        text={"Joint Embedding between 3D Scan and CAD objects"}*/}
                {/*        image={JointEmbedding}*/}
                {/*        tags={"ICCV 2019"}*/}
                {/*    />*/}
                {/*</div>*/}
                {/*<div className="col-4">*/}
                {/*    <HighlightCard*/}
                {/*        title={"Scan2CAD"}*/}
                {/*        text={"Scan2CAD: Learning CAD Model Alignment in RGB-D Scans"}*/}
                {/*        image={Scan2Cad}*/}
                {/*        tags={"CVPR 2019"}*/}
                {/*    />*/}
                {/*</div>*/}
                {/*<div className="col-4">*/}
                {/*    <HighlightCard*/}
                {/*        title={"Transfer Learning"}*/}
                {/*        text={"Transfer Learning between Synthetic and Real Data"}*/}
                {/*        image={MasterThesis}*/}
                {/*        tags={"TUM 2018, Master Thesis"}*/}
                {/*    />*/}
                {/*</div>*/}
            </div>
        </Box>
    );
};

const Description = () => {
    return (
        <div style={{textAlign: "justify"}}>
            <p>
                Hi there! I'm Manuel, a PhD candidate at the <a href="https://niessnerlab.org" target="__blank">Visual Computing
                Group
                of Matthias Nießner</a> at the <a href="https://www.tum.de" target="__blank">Universität München (TUM)</a>,
                Germany.
                My work focuses on 3D Scene Understanding and Reconstruction. I'm also interested in Geometric Deep Learning, Generative Models for 3D Data and Shape Analysis.
            </p>
            <p>
                Before the joining the group, I did my Master's Thesis at the same
                group about the topic <Link to={"/research#transfer-learning"}> Learning between Synthetic and
                Real Data</Link>. This thesis concludes the <em>Informatics: Games
                Engineering program (M.Sc)</em> with the specializations of <em>Computer
                Graphics and Animation</em> and <em>Hardware-aware Programming</em>.
            </p>
            <p>
                In 2015, I received my Bachelor's degree (B.Sc) Informatics:
                Games Engineering from the <a href="https://www.tum.de" target="__blank">Universität München (TUM)</a>.
                From August 2016 until June 2017, I took part in the Erasmus
                mobility program, in which I was studying at <a href="https://www.chalmers.se" target="__blank">Chalmers University
                of Technology, Sweden</a>.

            </p>
            <p>
                Between April and June 2019 I visited the <a href="https://geometry.stanford.edu/index.html" target="__blank">Geometric Computation Group of Leonidas Guibas</a> at <a href="https://www.stanford.edu" target="__blank">Stanford University, USA</a>.
            </p>
            {/*<p>*/}
            {/*    During my studies, I developed a high interest in Computer*/}
            {/*    Graphics, Engine programming and Software Architecture. More*/}
            {/*    recently, with the topic of my Master's Thesis and the*/}
            {/*    internship I extensively dove into Deep Learning for visual*/}
            {/*    applications.*/}
            {/*    Besides these fields I'm also interested in Data Visualization,*/}
            {/*    UI/UX Design, 3D Modelling, Game Design and current trends in*/}
            {/*    Graphics APIs (Vulkan).*/}

            {/*</p>*/}
            {/*<p>*/}
            {/*    If you are interested about my previous work, please check out*/}
            {/*    my projects and my CV!*/}
            {/*</p>*/}
        </div>
    );
};

const AboutMe = () => {
    return (
        <Box>
            <div className="row">
                <div className="col-12 px-3">
                    <h5 className="text-secondary text-center pt-1 pb-2">About
                        Me</h5>
                    <Description/>
                </div>
            </div>
        </Box>
    );
};

export default function Home({data}) {
    return (
        <Layout>
        <SEO title="Home"/>
        <ShortProfile
            name="Manuel Dahnert"
            position="PhD Candidate @ Visual Computing Group (TU Munich, Germany)"
            keywords={["3D Scene Understanding", "Generative Models", "Geometric Deep Learning"]}
            portrait={data.portrait}
        />
        <hr className={"my-3"} />
        <AboutMe/>
        <hr className={"my-3"} />
        <Highlights highlights={data.highlights.nodes}/>
        <hr className={"my-3"} />

    </Layout>
    )
}

export const query = graphql`
query SiteInfo{
    portrait: file(relativePath: { eq: "portrait.jpg" }) {
        childImageSharp {
            fluid {
            ...GatsbyImageSharpFluid,

            },
        }
    }

    highlights: allMarkdownRemark(
        limit: 3, 
        sort: {fields: frontmatter___date, order: DESC}
        filter: {fileAbsolutePath: {regex: "/(src/papers)/"}}
    ) {
    nodes {
      frontmatter {
        title
        slug
        year
        conference_short
        thumb {
          childImageSharp {
            fluid {
                ...GatsbyImageSharpFluid
            }
          }
        }
        pdf
      }
    }
  }
}
`